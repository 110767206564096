import React, { JSX, useCallback, useMemo } from 'react';
import { tCommon } from '@ankr.com/raas-utils';
import { LoadingButton } from '@ankr.com/ui';
import { Box, Button, Card, Typography } from '@mui/material';

import { useAppDispatch } from '../../../../../../store/useAppDispatch';
import { DialogId } from '../../../../../common/actions/openDialog';
import { TERMS_OF_USE_URL } from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { GRADE } from '../../../../RollupConst';
import { useGetPlansQuery } from '../../api/getPlans';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { useRollupDeployStepsState } from '../../hooks/useRollupDeployStepsState';
import {
  RollupDeployStepsEnum,
  updateStepsData,
} from '../../store/rollupDeployStepsSlice';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

export function DeployRollupControlPanel(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { grade, stack, planUuid } = useRollupDeployState();

  const { data: plans } = useGetPlansQuery(
    {
      grade,
      stack,
    },
    {
      skip: !grade || !stack,
    },
  );

  const planPrice = useMemo(
    () => plans?.find(plan => plan.uuid === planUuid)?.price,
    [plans, planUuid],
  );

  const { handleOpen: handleRollupPlansOpen } = useDialog(DialogId.RollupPlans);

  const { handleOpen: handleRollupConfirmOpen } = useDialog(
    DialogId.RollupConfirm,
  );

  const dispatch = useAppDispatch();

  const rollupDeployStepsState = useRollupDeployStepsState();

  const isMainnet = useMemo(() => grade === GRADE.mainnet, [grade]);

  const hasGradeWithoutPlan = useMemo(
    () =>
      rollupDeployStepsState.step === RollupDeployStepsEnum.General &&
      !!grade &&
      !planUuid,
    [grade, planUuid, rollupDeployStepsState.step],
  );

  const currentStep = useMemo(() => {
    if (
      rollupDeployStepsState.step !== RollupDeployStepsEnum.General &&
      !isMainnet
    ) {
      return rollupDeployStepsState.step - 1;
    }
    return rollupDeployStepsState.step;
  }, [isMainnet, rollupDeployStepsState.step]);

  const handleBack = useCallback(() => {
    const delta =
      rollupDeployStepsState.step === RollupDeployStepsEnum.Configuration &&
      !isMainnet
        ? 2
        : 1;

    dispatch(
      updateStepsData({
        step:
          rollupDeployStepsState.step !== RollupDeployStepsEnum.General
            ? rollupDeployStepsState.step - delta
            : rollupDeployStepsState.step,
        passedStep: rollupDeployStepsState.passedStep,
      }),
    );
  }, [
    dispatch,
    isMainnet,
    rollupDeployStepsState.passedStep,
    rollupDeployStepsState.step,
  ]);

  const handleNext = useCallback(() => {
    if (rollupDeployStepsState.step === RollupDeployStepsEnum.InfraPackage) {
      handleRollupConfirmOpen();
    }
  }, [handleRollupConfirmOpen, rollupDeployStepsState.step]);

  return (
    <Card className={cx(classes.card, classes.formControlWrap)}>
      {!!planPrice && !planPrice.isZero() && (
        <Box className={classes.amountWrap}>
          <Box>
            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {t(keys.deployRollupTotalAmount.title)}
            </Typography>
            <Typography
              className={classes.sectionSubtitle}
              variant="body3"
              component="div"
            >
              {t(
                keys.deployRollupTotalAmount.hint,
                {
                  docUrl: TERMS_OF_USE_URL,
                },
                true,
              )}
            </Typography>
          </Box>
          <Box className={classes.amountSum}>
            <Typography
              className={classes.sectionTitle}
              variant="subtitle1"
              component="div"
            >
              {tCommon('number.price', { value: planPrice })}
            </Typography>
            {plans && plans.length > 1 && (
              <Button
                variant="text"
                size="small"
                color="primary"
                onClick={handleRollupPlansOpen}
                className={classes.amountPlansModalBtn}
              >
                {t(keys.deployRollupTotalAmount.changePlan)}
              </Button>
            )}
          </Box>
        </Box>
      )}

      <Box className={classes.formControlPanel}>
        <Button
          variant="outlined"
          onClick={handleBack}
          size="large"
          className={classes.formControlBtn}
          sx={{
            visibility:
              rollupDeployStepsState.step === RollupDeployStepsEnum.General
                ? 'hidden'
                : 'visible',
          }}
        >
          {t(keys.deployRollup.back)}
        </Button>

        <Typography variant="subtitle2" className={classes.sectionSubtitle}>
          {t(keys.deployRollup.pageCounter, {
            currentStep,
            total: isMainnet ? 4 : 3,
          })}
        </Typography>

        {hasGradeWithoutPlan ? (
          <Button
            onClick={handleRollupPlansOpen}
            size="large"
            className={classes.formControlBtn}
          >
            {t(keys.deployRollup.choosePlan)}
          </Button>
        ) : (
          <LoadingButton
            type="submit"
            onClick={handleNext}
            size="large"
            disabled={!planUuid}
            className={classes.formControlBtn}
          >
            {rollupDeployStepsState.step === RollupDeployStepsEnum.InfraPackage
              ? t(keys.deployRollup.confirm)
              : t(keys.deployRollup.next)}
          </LoadingButton>
        )}
      </Box>
    </Card>
  );
}
