import { Hours, Milliseconds } from '@ankr.com/raas-types';

import { IS_PROD } from './environment';

export const PROJECT_KEY = import.meta.env.VITE_PROJECT_KEY;
export const PUBLIC_DIR = import.meta.env.VITE_PUBLIC_DIR;
export const HOMEPAGE = import.meta.env.VITE_HOMEPAGE;
export const PROJECT_TITLE = import.meta.env.VITE_TITLE;

export const STAKING_URL = import.meta.env.VITE_STAKING_URL;
export const STAKING_DEFI_URL = import.meta.env.VITE_STAKING_DEFI_URL;
export const STAKING_DOCS_URL = import.meta.env.VITE_STAKING_DOCS_URL;
export const USER_SUPPORT_URL = import.meta.env.VITE_USER_SUPPORT_URL;
export const HUBSPOT_URL = import.meta.env.VITE_HUBSPOT_URL;
export const INTEGRATIONS_PROMO_URL = import.meta.env
  .VITE_INTEGRATIONS_PROMO_URL;
export const GRANTS_DOCS_URL = import.meta.env.VITE_GRANTS_DOCS_URL;

export const CUSTOM_GAS_TOKEN_DOCS_URL = import.meta.env
  .VITE_CUSTOM_GAS_TOKEN_DOCS_URL;

export const SELF_SEQUENCER_DOCS_URL = import.meta.env
  .VITE_SELF_SEQUENCER_DOCS_URL;
export const ROLLUP_DEPLOYMENT_DOCS_URL = import.meta.env
  .VITE_ROLLUP_DEPLOYMENT_DOCS_URL;

export const ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL = import.meta.env
  .VITE_ROLLUP_ARBITRUM_DEPLOYMENT_BRIDGE_DOCS_URL;

export const ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL = import.meta.env
  .VITE_ROLLUP_OPTIMISM_DEPLOYMENT_BRIDGE_DOCS_URL;

export const POWERED_BY_URL = 'https://www.ankr.com/rollup-as-a-service-raas/';

export const TERMS_OF_USE_URL = `${HOMEPAGE}static/doc/Asphere_-_Terms_of_Service_-_No_Code_Deployer_App.pdf`;

// TODO: min price for stack plans
export const MIN_MAINNET_PACKAGE_PRICE = 2750;
export const MIN_TESTNET_PACKAGE_PRICE = 99;

export const ROLLUP_TESTNET_DURATION: Hours = 48;

export const SELF_SEQUENCER_FUNDS = '0.7 ETH';

export const ANKR_ETH_FOR_TESTNET_AMOUNT = 1;

export const ROLLUP_POLLING_INTERVAL: Milliseconds = 5000;

const MAX_FREE_TESTNET_ROLLUPS_STAGE = 1;
const MAX_FREE_TESTNET_ROLLUPS_PROD = 1;
export const MAX_FREE_TESTNET_ROLLUPS = IS_PROD
  ? MAX_FREE_TESTNET_ROLLUPS_PROD
  : MAX_FREE_TESTNET_ROLLUPS_STAGE;

export const MAX_CHAIN_ID = 1000000000;

export const METAMASK_INSTALL_URL = 'https://metamask.io/download/';
