import React, {
  JSX,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  AppModal,
  LabelWrap,
  QueryEmpty,
  QueryLoadingCentered,
} from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Check, Chip, ContentSwitcher } from '@ankr.com/ui';
import {
  Box,
  Button,
  FormHelperText,
  Grid,
  Stack,
  Tab,
  Typography,
} from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { ROLLUP_TESTNET_DURATION } from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import {
  GRADE,
  IDeployRollupFormPlanPayload,
  IPlanItem,
} from '../../../../RollupConst';
import { useGetPlansQuery } from '../../api/getPlans';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useHasMaxFreeTestnetRollups } from '../../hooks/useHasMaxFreeTestnetRollups';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';

export function DeployRollupPlansModal(): JSX.Element {
  const { classes } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const { grade, stack, planUuid } = useRollupDeployState();

  const prevStack = useRef<string | undefined>();

  const prevGrade = useRef<GRADE | undefined>();

  const { control, getValues, reset } = useForm<IDeployRollupFormPlanPayload>({
    defaultValues: {
      planUuid,
    },
  });

  const { dispatchData } = useDispatchRollupDeployData();

  const hasMaxFreeTestnetRollups = useHasMaxFreeTestnetRollups();

  const {
    data: plans,
    isFetching: isPlansFetching,
    isSuccess: isPlansSuccess,
  } = useGetPlansQuery(
    {
      grade,
      stack,
    },
    {
      skip: !grade || !stack,
    },
  );

  const {
    open: rollupPlansOpen,
    handleOpen: handleRollupPlansOpen,
    handleClose: handleRollupPlansClose,
  } = useDialog(DialogId.RollupPlans);

  /**
   * Open plans modal if current grade has multiple plans or select single
   */
  useEffect(() => {
    if (
      prevGrade.current !== grade &&
      !isPlansFetching &&
      isPlansSuccess &&
      !!plans &&
      !!plans.length
    ) {
      if (plans.length === 1) {
        dispatchData({
          ...getValues(),
          planUuid: plans[0].uuid,
        });
      } else {
        handleRollupPlansOpen();
      }
      prevGrade.current = grade;
      prevStack.current = stack;
    }
  }, [
    dispatchData,
    getValues,
    grade,
    handleRollupPlansOpen,
    isPlansFetching,
    isPlansSuccess,
    plans,
    stack,
  ]);

  /**
   * Reset plans form state if no plan has been selected
   */
  useEffect(() => {
    if (!planUuid) {
      reset();
    }
  }, [planUuid, reset]);

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
    handleRollupPlansClose();
  };

  const planItemsRender = useCallback(
    ({ plansList }: { plansList?: IPlanItem[] }) => {
      if (!plansList) {
        return null;
      }

      return (
        <Controller
          name="planUuid"
          control={control}
          rules={{
            required: tCommon('validation.required-one'),
          }}
          render={({ field, fieldState }) => (
            <Grid container spacing={7}>
              {plansList?.map(plan => {
                const alreadyInUse =
                  hasMaxFreeTestnetRollups &&
                  (!plan?.price || plan?.price.isZero());

                return (
                  <Grid item xs={12} sm={6} key={`plan_${plan.uuid}`}>
                    <LabelWrap
                      className={classes.labelWrap}
                      active={field.value === plan.uuid}
                      component={alreadyInUse ? 'div' : 'label'}
                    >
                      <Box className={classes.labelContentStretch}>
                        <Box>
                          <Typography variant="h6" component="div" mb={3}>
                            {plan.title}
                          </Typography>
                          <Box mb={5}>
                            {plan?.price && !plan?.price.isZero() ? (
                              <Chip
                                label={tCommon(
                                  'number.price-per-month',
                                  { value: plan?.price },
                                  true,
                                )}
                                color="primary"
                              />
                            ) : (
                              <Chip
                                label={t(keys.freeTrial, {
                                  hours: ROLLUP_TESTNET_DURATION,
                                })}
                                color="secondary"
                              />
                            )}
                          </Box>

                          {!!plan.transactionsPerDay && (
                            <Typography variant="subtitle3" component="div">
                              {t(
                                keys.deployRollupPlansModal.transactionsPerDay,
                                {
                                  amount: tCommon('number.compact-fixed-0', {
                                    value: plan.transactionsPerDay,
                                  }),
                                },
                              )}
                            </Typography>
                          )}
                          <Typography variant="body3" component="div" mb={5}>
                            {plan.description}
                          </Typography>

                          {!!plan.settings?.length && (
                            <Stack
                              direction="column"
                              justifyContent="flex-start"
                              spacing={1}
                            >
                              {plan.settings.map(settingItem => {
                                const titleString =
                                  typeof settingItem.value === 'boolean'
                                    ? settingItem.title
                                    : tCommon('common.two-spaced', {
                                        first: settingItem.value,
                                        second: settingItem.title,
                                      });

                                return (
                                  <Typography
                                    variant="body3"
                                    key={`settingItem_${settingItem.uuid}`}
                                    component="div"
                                    display="flex"
                                    alignItems="center"
                                    gap={2}
                                  >
                                    <Check color="success" />
                                    {titleString}
                                  </Typography>
                                );
                              })}
                            </Stack>
                          )}
                        </Box>

                        {alreadyInUse ? (
                          <Button
                            variant="contained"
                            size="large"
                            fullWidth
                            disabled
                          >
                            {t(keys.deployRollupPlansModal.alreadyInUse)}
                          </Button>
                        ) : (
                          <Button
                            variant={
                              field.value === plan.uuid
                                ? 'outlined'
                                : 'contained'
                            }
                            size="large"
                            component="label"
                            fullWidth
                            {...(field.value === plan.uuid
                              ? { onClick: handleRollupPlansClose }
                              : {})}
                          >
                            <input
                              type="radio"
                              {...field}
                              checked={field.value === plan.uuid}
                              value={plan.uuid}
                              hidden
                            />
                            {t(
                              field.value === plan.uuid
                                ? keys.deployRollupPlansModal.continueWith
                                : keys.deployRollupPlansModal.choosePlan,
                            )}
                          </Button>
                        )}
                      </Box>
                    </LabelWrap>
                  </Grid>
                );
              })}
              {!!fieldState.error?.message && (
                <Grid item xs={12}>
                  <FormHelperText error>
                    {fieldState.error?.message}
                  </FormHelperText>
                </Grid>
              )}
            </Grid>
          )}
        />
      );
    },
    [
      classes.labelContentStretch,
      classes.labelWrap,
      control,
      handleRollupPlansClose,
      hasMaxFreeTestnetRollups,
      keys.deployRollupPlansModal.alreadyInUse,
      keys.deployRollupPlansModal.choosePlan,
      keys.deployRollupPlansModal.continueWith,
      keys.deployRollupPlansModal.transactionsPerDay,
      keys.freeTrial,
      t,
    ],
  );

  const plansWithProver = useMemo(
    () =>
      plans?.filter(plan =>
        plan.settings?.some(setting => setting?.key === 'prover'),
      ),
    [plans],
  );
  const hasPlansWithProver = useMemo(
    () => !!plansWithProver && !!plansWithProver.length,
    [plansWithProver],
  );

  const plansWithoutProver = useMemo(
    () =>
      plans?.filter(
        plan => !plan.settings?.some(setting => setting?.key === 'prover'),
      ),
    [plans],
  );
  const hasPlansWithoutProver = useMemo(
    () => !!plansWithoutProver && !!plansWithoutProver.length,
    [plansWithoutProver],
  );

  const commonPlans = useMemo(() => {
    if (!hasPlansWithProver && hasPlansWithoutProver) {
      return plansWithoutProver;
    }
    if (!hasPlansWithoutProver && hasPlansWithProver) {
      return plansWithProver;
    }
  }, [
    hasPlansWithProver,
    hasPlansWithoutProver,
    plansWithProver,
    plansWithoutProver,
  ]);

  const [switcherValue, setSwitcherValue] = useState('without-prover');

  const handleSwitcherChange = (
    _event: React.SyntheticEvent,
    newValue: 'without-prover' | 'with-prover',
  ): void => {
    setSwitcherValue(newValue);
  };

  return (
    <AppModal
      open={rollupPlansOpen}
      onClose={handleRollupPlansClose}
      title={
        grade === GRADE.mainnet
          ? t(keys.deployRollupPlansModal.title)
          : t(keys.deployRollupPlansModal.testnetTitle)
      }
      classes={{ paper: classes.plansModalContent }}
      titleClassName={classes.plansModalTitle}
      titleCentered
    >
      {isPlansSuccess && !plans?.length && <QueryEmpty />}
      {isPlansFetching && !isPlansSuccess ? (
        <QueryLoadingCentered />
      ) : (
        <form onChange={handleFormChange} noValidate autoComplete="off">
          {hasPlansWithProver && hasPlansWithoutProver && (
            <Box display="flex" justifyContent="center" mb={8}>
              <ContentSwitcher
                value={switcherValue}
                onChange={handleSwitcherChange}
              >
                <Tab
                  value="without-prover"
                  label={t(keys.deployRollupPlansModal.withoutProver)}
                />
                <Tab
                  value="with-prover"
                  label={t(keys.deployRollupPlansModal.withProver)}
                />
              </ContentSwitcher>
            </Box>
          )}

          {hasPlansWithProver &&
            switcherValue === 'without-prover' &&
            planItemsRender({ plansList: plansWithoutProver })}
          {hasPlansWithoutProver &&
            switcherValue === 'with-prover' &&
            planItemsRender({ plansList: plansWithProver })}
          {!!commonPlans &&
            !!commonPlans.length &&
            planItemsRender({ plansList: commonPlans })}
        </form>
      )}
    </AppModal>
  );
}
