import React, { JSX, useCallback } from 'react';
import { Control, Controller } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { LabelWrap, QueryLoadingCentered } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Chip } from '@ankr.com/ui';
import { Box, FormHelperText, Grid, Tooltip, Typography } from '@mui/material';

import ExternalLinkThinIcon from '../../../../../common/assets/external-link-thin-icon.svg?react';
import { StackDALIcon } from '../../../../../common/components/StackDALIcon';
import {
  GRANTS_DOCS_URL,
  HUBSPOT_URL,
} from '../../../../../common/const/values';
import { useTranslation } from '../../../../../i18n';
import {
  IDALItem,
  IDeployRollupFormGeneralPayload,
  IStackItem,
} from '../../../../RollupConst';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import GrantsIcon from './assets/grants-icon.svg?react';

interface IDeployRollupFormStackList {
  control: Control<IDeployRollupFormGeneralPayload>;
  controllerName: keyof Omit<IDeployRollupFormGeneralPayload, 'grade'>;
  title: string;
  subtitle?: string;
  isDALList?: boolean;
  list?: IStackItem[] | IDALItem[];
  isLoading?: boolean;
}

export function DeployRollupFormStackList({
  control,
  controllerName,
  title,
  subtitle,
  isDALList,
  list,
  isLoading,
}: IDeployRollupFormStackList): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const renderComingSoonChip = useCallback(
    ({ comingSoon }: { comingSoon?: boolean }) => {
      if (comingSoon) {
        if (isDALList || !HUBSPOT_URL) {
          return (
            <Chip
              label={t(keys.deployRollup.comingSoon)}
              size="small"
              color="secondary"
              className={classes.smallChip}
            />
          );
        }
        if (HUBSPOT_URL) {
          return (
            <Chip
              label={
                <>
                  {t(keys.deployRollup.bookDemo)}
                  <ExternalLinkThinIcon />
                </>
              }
              size="small"
              color="secondary"
              className={classes.smallChip}
              component={Link}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              to={HUBSPOT_URL}
              rel="noreferrer"
              target="_blank"
              clickable
            />
          );
        }
      }
      return null;
    },
    [
      classes.smallChip,
      isDALList,
      keys.deployRollup.bookDemo,
      keys.deployRollup.comingSoon,
      t,
    ],
  );

  return (
    <Box className={classes.section}>
      <Box className={classes.sectionTitleWrap}>
        <Typography
          className={classes.sectionTitle}
          variant="subtitle1"
          component="div"
        >
          {title}
        </Typography>
        {!!subtitle && (
          <Typography
            className={classes.sectionSubtitle}
            variant="body3"
            component="div"
          >
            {subtitle}
          </Typography>
        )}
      </Box>
      {isLoading ? (
        <QueryLoadingCentered />
      ) : (
        <Grid container spacing={2.5}>
          <Controller
            name={controllerName}
            control={control}
            rules={{
              required: tCommon('validation.required-one'),
            }}
            render={({ field, fieldState }) => (
              <>
                {list?.map(item => (
                  <Grid
                    item
                    key={`${controllerName}_${item.uuid}`}
                    xs={12}
                    sm={6}
                    lg={list.length % 4 ? 4 : 3}
                  >
                    <LabelWrap
                      className={classes.labelWrap}
                      active={field.value === item.uuid}
                      component={item.comingSoon ? 'div' : 'label'}
                    >
                      <>
                        {item.comingSoon || (
                          <input
                            type="radio"
                            {...field}
                            checked={field.value === item.uuid}
                            value={item.uuid}
                            hidden
                          />
                        )}
                        <Box
                          mb={7.5}
                          display="flex"
                          justifyContent="space-between"
                          gap={2}
                          width="100%"
                        >
                          <StackDALIcon
                            keyString={item.key}
                            title={item.title}
                            className={classes.itemLogo}
                            isDAL={isDALList}
                          />

                          <Box
                            display="flex"
                            gap={1}
                            alignItems="flex-start"
                            flexWrap="wrap"
                          >
                            {GRANTS_DOCS_URL && item.hasGrants && (
                              <Tooltip
                                title={t(keys.deployRollup.grantAvailable)}
                              >
                                <Box fontSize={0}>
                                  <Chip
                                    label={<GrantsIcon />}
                                    size="small"
                                    color="secondary"
                                    className={classes.smallChip}
                                  />
                                </Box>
                              </Tooltip>
                            )}
                            {renderComingSoonChip({
                              comingSoon: item.comingSoon,
                            })}
                          </Box>
                        </Box>
                        <Typography
                          variant="subtitle2"
                          component="div"
                          mb={3}
                          className={cx(
                            item.comingSoon && classes.textDisabled,
                          )}
                        >
                          {item.title}
                        </Typography>
                        <Typography
                          variant="body3"
                          component="div"
                          className={cx(
                            item.comingSoon && classes.textDisabled,
                          )}
                        >
                          {item.description}
                        </Typography>
                      </>
                    </LabelWrap>
                  </Grid>
                ))}
                {!!fieldState.error?.message && (
                  <Grid item xs={12}>
                    <FormHelperText error>
                      {fieldState.error?.message}
                    </FormHelperText>
                  </Grid>
                )}
              </>
            )}
          />
        </Grid>
      )}
    </Box>
  );
}
