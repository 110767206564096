import { Locale } from '../../../i18n';

export const paymentStatusTranslation = {
  [Locale.en]: {
    successModal: {
      title: 'Success',
      paidAmount: 'Amount was paid',
      successHint: 'Your payment was successful!',
      paidInitializingHint:
        'Your payment was successful!<br /> Initializing your rollup is underway!',
      freeInitializingHint:
        'Your balance check was successful!<br /> Initializing your rollup is underway!',
      successInitializedHint:
        'Your payment was successful and initialization is complete.<br /> You can track the rollup status in the dashboard.',
      successPendingDepositMainnetHint:
        'Your payment was successful and initialization is underway.<br /> Please deposit {amount} to the sequencer address to begin deploying mainnet.',
      successPendingDepositPaidTestnetHint:
        'Your payment was successful and initialization is underway.<br /> Please deposit {amount} to the sequencer address to begin deploying testnet.',
      successPendingDepositFreeTestnetHint:
        'Please deposit {amount} to the sequencer address to begin deploying rollup.',
      button: 'Close',
      initializing: 'Initializing',
      deposit: 'Deposit {amount}',
    },
    failedModal: {
      title: 'Payment Failed',
      hint1: 'Oops, something went wrong. Your payment has not been received',
      hint2: 'Please try again or contact support.',
      button: 'Try Again',
    },
  },
};
