import { Locale } from '../../../i18n';

export const deployRollupTranslation = {
  [Locale.en]: {
    deployRollup: {
      pageTitle: 'Deploy New Rollup',
      getStarted: 'Get started with rollups',
      viewInstructions: 'View Instructions',
      viewRequirements: 'View requirements',
      comingSoon: 'Coming Soon',
      bookDemo: 'Book Demo',
      grantAvailable: 'Grant Available',
      back: 'Go Back',
      next: 'Next',
      choosePlan: 'Choose Plan',
      confirm: 'Confirm',
      pageCounter: '{currentStep} / {total}',
    },
    deployRollupPlansModal: {
      title: 'Plans that scale with you',
      testnetTitle: 'Choose your testnet option',
      choosePlan: 'Choose Plan',
      continueWith: 'Continue with',
      alreadyInUse: 'Already In Use',
      withProver: 'With Prover',
      withoutProver: 'Without Prover',
      transactionsPerDay: 'For <{amount} transactions a day.',
    },
    deployRollupStepper: {
      general: 'Essentials',
      sequencer: 'Sequencer',
      configuration: 'Configuration',
      infra: 'Infrastructure',
    },
    deployRollupStack: {
      title: 'Stack',
      subtitle:
        'Rollup stacks enable developers to build dedicated, customized, application-specific rollups from scratch.',
    },
    deployRollupGrade: {
      title: 'Network',
      subtitle:
        'Your rollup’s network grade determines the level of support, scalability of features available to your developer team.',
      testnetHint:
        'Launch the test network for your new rollup to begin exploring functionality (only {availableTestnets, plural, =1 {# testnet} other {# testnets}} can be enabled per {testnetDuration} hour time limit). You must have {amount} of the {token} token in your wallet to proceed with this option. Find {token} on <a href="{stakingDefiUrl}" target="_blank" rel="noreferrer">major DEXs</a> or stake ETH to receive the token on  <a href="{stakingUrl}" target="_blank" rel="noreferrer">Ankr Staking</a>.',
      hasPaidTestnetsHint:
        'Launch the test version of your rollup to either explore the functionality ({testnetDuration}-hour free trial) or use it as a permanent testing environment to experiment with your transactions and dapps before they go live.',
      mainnetHint:
        'Launch the live version of your rollup that is available for real transactions and dApp creation with a minimum network of nodes. To finalize rollup deployment, you must make the ETH security deposit shown on the summary page.',
      alreadyInUse: 'Already In Use',
      trialAlreadyInUse: 'Trial Rollup Already In Use',
    },
    deployRollupDataAvailableLayer: {
      title: 'Data Availability Layer',
      subtitle:
        'The blockchain responsible for proof verification and dispute resolution.',
    },
    deployRollupSequencer: {
      title: 'Manage Sequencer',
      subtitle:
        'Sequencer nodes play a crucial role in ordering and batching transactions before submission to the layer 1 chain.',
      productTitle: 'Asphere Managed Sequencing*',
      productHint:
        'When choosing Asphere to manage your sequencer, this will cover all setup, deployment, launch, and maintenance needs. In order to deploy Mainnet, you will need to fund the sequencer with {amount} after billing.',
      productFootnote:
        '*Additional fees apply, see <a href="{docUrl}" target="_blank" rel="noreferrer">terms of service</a> for more details.',

      selfTitle: 'Manage Sequencer Myself',
      selfHint:
        'We provide instructions for self-management and require the sharing of the sequencer endpoint for deployment. In order to deploy Mainnet, you will need to fund the deployer address with {amount} after billing.',

      addressLabel: 'Sequencer Address',
      urlLabel: 'Sequencer URL',
    },
    deployRollupConfigurationProps: {
      chainId: 'Chain ID',
      chainIdTooltip:
        'The chain ID for your network. This can be anything you like on testnets. But must be unique for mainnets.',
      chainIdHint: 'The chain ID can not be modified once deployed',
      networkName: 'Rollup Name',
      customGasTokenHint:
        'Once your rollup is deployed we will manually deploy your custom gas token',
      customGasTokenAddress: 'Custom Gas Token Address',
    },
    deployRollupConfigurationToken: {
      tokensHint:
        '{project} allows you to benefit from a dual token system leveraging financial benefits from ankrETH as gas token and your own native token for community growth participation',
      title: 'Select your Gas Token',
      subtitleMainnet:
        'As a liquid staking token, ankrETH collects constant ETH staking rewards for holders.',
      subtitleTestnet:
        'There is only one option available for testnet. You will find more options in the mainnet section, including the possibility to select a native token.',
      wantAddTokenHint: 'Want to add your native token?',
      customGasTokenHint:
        'Make sure the token’s contract address is on <span class="cgt-chain">{chain}</span>',
      isValidAddress: 'Valid address',
      isErc20: 'Valid ERC-20',
      is18Decimals: '18 decimals',
      isNonRebasing: 'Non-rebasing',
    },

    freeTrial: 'Free for {hours} hours',

    deployRollupInfra: {
      changePlan: 'Change Plan',
    },

    deployRollupTotalAmount: {
      title: 'Current Amount',
      hint: 'By making a purchase, you agree to the <a href="{docUrl}" target="_blank" rel="noreferrer">Terms of Use</a>. The price for launching your rollup with chosen options.',
      changePlan: 'Change plan',
    },

    deployRollupConfirmModal: {
      title: 'Summary',
      subtitle: 'Check your specification.',
      confirm: 'Confirm',
      confirmAndPay: 'Confirm and Pay {price}',
      cancel: 'Cancel',
      poweredBy: 'Powered by',

      stack: 'Stack',
      chainId: 'Chain ID',
      networkName: 'Rollup Name',
      grade: 'Network',
      DAL: 'Data Availability Layer',
      sequencerAddress: 'Sequencer Address',
      sequencerUrl: 'Sequencer URL',
      gasToken: 'Gas Token',
      success: 'Success',
      goToDashBoard: 'Go to Dashboard',

      depositHint:
        'To finalize rollup deployment, you must make a security deposit of {amount} to your sequencer (subject to slashing rules).',
    },

    deployRollupProveModal: {
      title: 'Prove {token}',
      prove: 'Connect MetaMask and prove balance',
      cancel: 'Cancel',
      hint: 'You must have {amount} {token} token in your wallet to proceed.<br /> No {token} in your wallet? No problem. To proceed, simply acquire ETH and stake with Ankr. Learn more about Ankr staking <a href="{stakingDocsUrl}" target="_blank" rel="noreferrer">here</a>.',
      stakeButton: 'Stake ETH with Ankr',
    },
  },
};
