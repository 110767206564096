import { IDALItem, IDALItemApi } from '../../../RollupConst';

export function mapDALFromApi(DAL: IDALItemApi): IDALItem {
  return {
    description: DAL.description,
    key: DAL.key || '',
    title: DAL.title,
    uuid: DAL.uuid,
    comingSoon: DAL.coming_soon,
    gasTokens: DAL.gas_tokens,
    hasGrants: DAL.has_grants,
  };
}

export function mapDALsFromApi(DALs: IDALItemApi[]): IDALItem[] {
  return DALs?.map((DAL: IDALItemApi) => mapDALFromApi(DAL));
}
