import React, { JSX, useCallback, useEffect, useMemo, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LabelWrap } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Chip } from '@ankr.com/ui';
import { Box, Card, FormHelperText, Grid, Typography } from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import {
  ANKR_ETH_FOR_TESTNET_AMOUNT,
  MAX_FREE_TESTNET_ROLLUPS,
  MIN_MAINNET_PACKAGE_PRICE,
  MIN_TESTNET_PACKAGE_PRICE,
  ROLLUP_TESTNET_DURATION,
  STAKING_DEFI_URL,
  STAKING_URL,
} from '../../../../../common/const/values';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { getToken } from '../../../../../common/utils/getToken';
import { useTranslation } from '../../../../../i18n';
import {
  DECLARED_TOKEN,
  GRADE,
  IDeployRollupFormGeneralPayload,
} from '../../../../RollupConst';
import { useGetDALsQuery } from '../../api/getDALs';
import { useGetPlansQuery } from '../../api/getPlans';
import { useGetStacksQuery } from '../../api/getStacks';
import { useDispatchRollupDeployData } from '../../hooks/useDispatchRollupDeployData';
import { useHasMaxFreeTestnetRollups } from '../../hooks/useHasMaxFreeTestnetRollups';
import { useRollupDeployState } from '../../hooks/useRollupDeployState';
import { deployRollupTranslation } from '../../translation';
import { useDeployRollupStyles } from '../../useDeployRollupStyles';
import { DeployRollupControlPanel } from '../DeployRollupControlPanel';
import { DeployRollupFormHeader } from '../DeployRollupFormHeader';
import { DeployRollupFormStackList } from '../DeployRollupFormStackList';

export function DeployRollupFormGeneral(): JSX.Element {
  const { classes, cx } = useDeployRollupStyles();

  const { keys, t } = useTranslation(deployRollupTranslation);

  const prevStack = useRef<string | undefined>();

  const prevGrade = useRef<GRADE | undefined>();

  const prevPlanUuid = useRef<string | undefined>();

  const { stack, grade, dataAvailabilityLayer, planUuid } =
    useRollupDeployState();

  const { dispatchData } = useDispatchRollupDeployData();

  const {
    data: stacks,
    isLoading: isStacksLoading,
    isSuccess: isStacksSuccess,
  } = useGetStacksQuery();

  const { control, handleSubmit, getValues, setValue, resetField } =
    useForm<IDeployRollupFormGeneralPayload>({
      defaultValues: {
        stack,
        grade,
        dataAvailabilityLayer,
      },
    });

  const {
    data: testnetPlans,
    isFetching: isTestnetPlansFetching,
    isSuccess: isTestnetPlansSuccess,
  } = useGetPlansQuery(
    {
      grade: GRADE.testnet,
      stack,
    },
    {
      skip: !isStacksSuccess || !stack,
    },
  );

  const {
    data: mainnetPlans,
    isFetching: isMainnetPlansFetching,
    isSuccess: isMainnetPlansSuccess,
  } = useGetPlansQuery(
    {
      grade: GRADE.mainnet,
      stack,
    },
    {
      skip: !isStacksSuccess || !stack,
    },
  );

  const plans = useMemo(() => {
    switch (grade) {
      case GRADE.testnet:
        return testnetPlans;
      case GRADE.mainnet:
        return mainnetPlans;
      default:
        return undefined;
    }
  }, [grade, mainnetPlans, testnetPlans]);

  const isPlansFetching = useMemo(
    () => isTestnetPlansFetching || isMainnetPlansFetching,
    [isMainnetPlansFetching, isTestnetPlansFetching],
  );

  const isPlansSuccess = useMemo(
    () => isTestnetPlansSuccess && isMainnetPlansSuccess,
    [isMainnetPlansSuccess, isTestnetPlansSuccess],
  );

  const { handleOpen: handleRollupPlansOpen } = useDialog(DialogId.RollupPlans);

  const handlePlansModalOpen = useCallback(() => {
    if (!isPlansFetching && isPlansSuccess && !!plans && plans.length > 1) {
      handleRollupPlansOpen();
    }
  }, [handleRollupPlansOpen, isPlansFetching, isPlansSuccess, plans]);

  const currentStack = useMemo(
    () => stacks?.find(stackItem => stackItem.uuid === stack),
    [stack, stacks],
  );

  const disableMainnetRollup = useMemo(
    () => !!currentStack?.mainnetComingSoon,
    [currentStack?.mainnetComingSoon],
  );

  const hasMaxFreeTestnetRollups = useHasMaxFreeTestnetRollups();

  const hasPaidTestnetRollup = useMemo(() => {
    return !!testnetPlans?.some(
      plan => plan.grade === GRADE.testnet && !plan.price.isZero(),
    );
  }, [testnetPlans]);

  const disableTestnetRollup = useMemo(() => {
    return !hasPaidTestnetRollup && hasMaxFreeTestnetRollups;
  }, [hasMaxFreeTestnetRollups, hasPaidTestnetRollup]);

  /**
   * Reset plan if grade is testnet and additional testnet plans are not available
   */
  useEffect(() => {
    if (
      !!isPlansSuccess &&
      !!stack &&
      grade === GRADE.testnet &&
      disableTestnetRollup
    ) {
      setValue('grade', '');
      dispatchData({
        ...getValues(),
        grade: '',
      });
    }
  }, [
    disableTestnetRollup,
    dispatchData,
    getValues,
    grade,
    isPlansSuccess,
    setValue,
    stack,
  ]);

  /**
   * Reset plan if stack or grade changed
   */
  useEffect(() => {
    if (
      (!!prevGrade.current && prevGrade.current !== grade) ||
      (!!prevStack.current && prevStack.current !== stack)
    ) {
      dispatchData({
        ...getValues(),
        planUuid: '',
      });
    }
    prevGrade.current = grade;
    prevStack.current = stack;
  }, [dispatchData, getValues, grade, stack]);

  /**
   * Reset DAL if plan changed
   */
  useEffect(() => {
    if (!!prevPlanUuid.current && prevPlanUuid.current !== planUuid) {
      resetField('dataAvailabilityLayer');
      dispatchData({
        ...getValues(),
        dataAvailabilityLayer: '',
      });
    }
    prevPlanUuid.current = planUuid;
  }, [dispatchData, getValues, planUuid, resetField]);

  const { data: DALs, isLoading: isDALsLoading } = useGetDALsQuery(
    {
      grade,
      stack,
    },
    {
      skip: !isStacksSuccess || !grade || !stack,
    },
  );

  const handleFormChange = () => {
    setTimeout(() => {
      dispatchData(getValues());
    });
  };

  const onSubmit = useCallback(
    (payload: IDeployRollupFormGeneralPayload) => {
      dispatchData(payload, true);
    },
    [dispatchData],
  );

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      onChange={handleFormChange}
      noValidate
      autoComplete="off"
    >
      <Card className={classes.card}>
        <DeployRollupFormHeader />

        <DeployRollupFormStackList
          control={control}
          controllerName="stack"
          title={t(keys.deployRollupStack.title)}
          subtitle={t(keys.deployRollupStack.subtitle)}
          list={stacks}
          isLoading={isStacksLoading}
        />

        {isStacksSuccess && stack && isPlansSuccess && (
          <Box className={classes.section}>
            <Box className={classes.sectionTitleWrap}>
              <Typography
                className={classes.sectionTitle}
                variant="subtitle1"
                component="div"
              >
                {t(keys.deployRollupGrade.title)}
              </Typography>
              <Typography
                className={classes.sectionSubtitle}
                variant="body3"
                component="div"
              >
                {t(keys.deployRollupGrade.subtitle)}
              </Typography>
            </Box>

            <Grid container spacing={2.5}>
              <Controller
                name="grade"
                control={control}
                rules={{
                  required: tCommon('validation.required-one'),
                }}
                render={({ field, fieldState }) => (
                  <>
                    <Grid item xs={12} sm={6}>
                      <LabelWrap
                        className={classes.labelWrap}
                        active={field.value === GRADE.testnet}
                        component={disableTestnetRollup ? 'div' : 'label'}
                        {...(!disableTestnetRollup &&
                        field.value === GRADE.testnet
                          ? { onClick: handlePlansModalOpen }
                          : {})}
                      >
                        <>
                          {disableTestnetRollup || (
                            <input
                              type="radio"
                              {...field}
                              checked={field.value === GRADE.testnet}
                              value={GRADE.testnet}
                              hidden
                            />
                          )}
                          <Box
                            mb={3}
                            display="flex"
                            gap={2}
                            justifyContent="space-between"
                            flexWrap="wrap"
                            width="100%"
                          >
                            <Box display="flex" gap={2} flexWrap="wrap">
                              <Typography
                                variant="subtitle2"
                                className={cx(
                                  disableTestnetRollup && classes.textDisabled,
                                )}
                              >
                                {tCommon('common.testnet')}
                              </Typography>
                              <Chip
                                label={t(keys.freeTrial, {
                                  hours: ROLLUP_TESTNET_DURATION,
                                })}
                                size="small"
                                color="secondary"
                              />
                              {hasPaidTestnetRollup && (
                                <Chip
                                  label={tCommon('number.price-month', {
                                    value: MIN_TESTNET_PACKAGE_PRICE,
                                  })}
                                  size="small"
                                  color="primary"
                                />
                              )}
                            </Box>
                            {disableTestnetRollup && (
                              <Chip
                                label={t(keys.deployRollupGrade.alreadyInUse)}
                                size="small"
                                color="error"
                              />
                            )}
                            {hasMaxFreeTestnetRollups &&
                              hasPaidTestnetRollup && (
                                <Chip
                                  label={t(
                                    keys.deployRollupGrade.trialAlreadyInUse,
                                  )}
                                  size="small"
                                  color="error"
                                />
                              )}
                          </Box>
                          <Typography
                            variant="body3"
                            component="div"
                            className={cx(
                              disableTestnetRollup && classes.textDisabled,
                            )}
                          >
                            {hasPaidTestnetRollup
                              ? t(keys.deployRollupGrade.hasPaidTestnetsHint, {
                                  testnetDuration: ROLLUP_TESTNET_DURATION,
                                })
                              : t(
                                  keys.deployRollupGrade.testnetHint,
                                  {
                                    availableTestnets: MAX_FREE_TESTNET_ROLLUPS,
                                    testnetDuration: ROLLUP_TESTNET_DURATION,
                                    amount: ANKR_ETH_FOR_TESTNET_AMOUNT,
                                    token: getToken({
                                      value: DECLARED_TOKEN.ankreth,
                                    }).name,
                                    stakingUrl: STAKING_URL,
                                    stakingDefiUrl: STAKING_DEFI_URL,
                                  },
                                  true,
                                )}
                          </Typography>
                        </>
                      </LabelWrap>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelWrap
                        className={classes.labelWrap}
                        component={disableMainnetRollup ? 'div' : 'label'}
                        active={field.value === GRADE.mainnet}
                        {...(!disableMainnetRollup &&
                        field.value === GRADE.mainnet
                          ? { onClick: handlePlansModalOpen }
                          : {})}
                      >
                        <>
                          {disableMainnetRollup || (
                            <input
                              type="radio"
                              {...field}
                              checked={field.value === GRADE.mainnet}
                              value={GRADE.mainnet}
                              hidden
                            />
                          )}
                          <Box
                            display="flex"
                            gap={2}
                            justifyContent="space-between"
                            width="100%"
                          >
                            <Box mb={3} display="flex" gap={2}>
                              <Typography
                                variant="subtitle2"
                                className={cx(
                                  disableMainnetRollup && classes.textDisabled,
                                )}
                              >
                                {tCommon('common.mainnet')}
                              </Typography>
                              {disableMainnetRollup || (
                                <Chip
                                  label={tCommon('number.price-from-month', {
                                    value: MIN_MAINNET_PACKAGE_PRICE,
                                  })}
                                  size="small"
                                  color="primary"
                                />
                              )}
                            </Box>
                            {disableMainnetRollup && (
                              <Chip
                                label={t(keys.deployRollup.comingSoon)}
                                size="small"
                                color="secondary"
                              />
                            )}
                          </Box>
                          <Typography
                            variant="body3"
                            component="div"
                            mb={4}
                            className={cx(
                              disableMainnetRollup && classes.textDisabled,
                            )}
                          >
                            {t(keys.deployRollupGrade.mainnetHint)}
                          </Typography>
                        </>
                      </LabelWrap>
                    </Grid>
                    {!!fieldState.error?.message && (
                      <Grid item xs={12}>
                        <FormHelperText error>
                          {fieldState.error?.message}
                        </FormHelperText>
                      </Grid>
                    )}
                  </>
                )}
              />
            </Grid>
          </Box>
        )}

        {!!grade && (
          <DeployRollupFormStackList
            control={control}
            controllerName="dataAvailabilityLayer"
            title={t(keys.deployRollupDataAvailableLayer.title)}
            subtitle={t(keys.deployRollupDataAvailableLayer.subtitle)}
            list={DALs}
            isLoading={isDALsLoading}
            isDALList
          />
        )}
      </Card>

      <DeployRollupControlPanel />
    </form>
  );
}
