import React, {
  JSX,
  KeyboardEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Delete, More } from '@ankr.com/ui';
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import { DialogId } from '../../../../../common/actions/openDialog';
import { useDialog } from '../../../../../common/hooks/useDialog';
import { useTranslation } from '../../../../../i18n';
import { RollupDeleteModal } from '../RollupDeleteModal';
import { rollupSubActionsMenuTranslation } from './translation';
import { useRollupSubActionsMenuStyles } from './useRollupSubActionsMenuStyles';

interface IRollupSubActionsMenuProps {
  rollupUuid?: string;
}

export function RollupSubActionsMenu({
  rollupUuid,
}: IRollupSubActionsMenuProps): JSX.Element | null {
  const { classes, cx } = useRollupSubActionsMenuStyles();

  const { keys, t } = useTranslation(rollupSubActionsMenuTranslation);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = useCallback(() => {
    setOpen(prevOpen => !prevOpen);
  }, []);

  const handleClose = useCallback((event: Event | SyntheticEvent) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  }, []);

  const { handleOpen: handleRollupDeleteModalOpen } = useDialog(
    DialogId.RollupDelete,
  );

  const handleDelete = useCallback(
    (event: Event | SyntheticEvent) => {
      handleClose(event);
      handleRollupDeleteModalOpen();
    },
    [handleClose, handleRollupDeleteModalOpen],
  );

  const handleListKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === 'Escape') {
      setOpen(false);
    }
  }, []);

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current && !open) {
      anchorRef.current?.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Box className={classes.root}>
      <Button
        ref={anchorRef}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup="true"
        className={cx(classes.button, open && classes.buttonActive)}
        onClick={handleToggle}
        size="small"
      >
        <More className={classes.buttonIcon} />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        placement="bottom-end"
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-end' ? 'right top' : 'right bottom',
            }}
          >
            <Paper elevation={1}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  onKeyDown={handleListKeyDown}
                  disablePadding
                >
                  <MenuItem onClick={handleDelete}>
                    <Delete
                      className={cx(
                        classes.menuItemIcon,
                        classes.menuItemIconDanger,
                      )}
                    />
                    {t(keys.delete)}
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <RollupDeleteModal rollupUuid={rollupUuid} />
    </Box>
  );
}
