import React, { JSX, useMemo } from 'react';
import { useScrollToTop } from '@ankr.com/raas-hooks';
import { Notifications, QueryLoadingAbsolute } from '@ankr.com/raas-ui';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { CssBaseline, ThemeProvider } from '@mui/material';

import '@ankr.com/assets/public/fonts/index.css';

import { useAuth } from '../../../auth/hooks/useAuth';
import { PROJECT_KEY } from '../../../common/const/values';
import { useLocaleInit } from '../../../i18n/hooks/useLocaleInit';
import { fraxTheme } from '../../../themes/fraxTheme';
import { mainTheme } from '../../../themes/mainTheme';

interface IAppBaseProps {
  children: JSX.Element;
}

export const muiCache = createCache({
  key: 'mui',
});

export function AppBase({ children }: IAppBaseProps): JSX.Element {
  useScrollToTop();

  const theme = useMemo(() => {
    switch (PROJECT_KEY) {
      case 'frax':
        return fraxTheme;
      default:
        return mainTheme;
    }
  }, []);

  const { isLocaleReady } = useLocaleInit();

  const { isSignedIn } = useAuth();

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {isLocaleReady && isSignedIn !== undefined ? (
          children
        ) : (
          <QueryLoadingAbsolute />
        )}
        <Notifications />
      </ThemeProvider>
    </CacheProvider>
  );
}
