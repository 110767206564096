import { Web3Address } from '@ankr.com/raas-types';
import { queryFnNotifyWrapper } from '@ankr.com/raas-ui';
import { tCommon } from '@ankr.com/raas-utils';
import { Web3 } from 'web3';

import { Gateways, mainApi } from '../../../../api';

interface IGetSignatureRes {
  address?: Web3Address;
  signature?: string;
}

export const { useGetSignatureMutation } = mainApi.injectEndpoints({
  endpoints: build => ({
    getSignature: build.mutation<IGetSignatureRes, void>({
      queryFn: queryFnNotifyWrapper<void, never, IGetSignatureRes>(async () => {
        if (!window.ethereum) {
          throw Error(tCommon('error.metamask-is-not-installed'));
        }

        const ottResponse = await Gateways.auth.api.get('/api/v1/ott', {
          method: 'GET',
        });

        const { ott } = ottResponse.data;

        if (ott) {
          const web3 = new Web3(window.ethereum);

          // Request the user to connect accounts (Metamask will prompt)
          await window.ethereum.request({ method: 'eth_requestAccounts' });

          // Get the connected accounts
          const accounts = await web3.eth.getAccounts();

          const address = accounts[0];

          if (address) {
            const signature = await web3.eth.personal.sign(ott, address, '');
            return { data: { address, signature } };
          }
        }
      }),
    }),
  }),
});
